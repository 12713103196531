import { render, staticRenderFns } from "./UnpublishedEvent.vue?vue&type=template&id=765a9938&scoped=true&"
import script from "./UnpublishedEvent.vue?vue&type=script&lang=js&"
export * from "./UnpublishedEvent.vue?vue&type=script&lang=js&"
import style0 from "./UnpublishedEvent.vue?vue&type=style&index=0&id=765a9938&prod&lang=scss&scoped=true&"
import style1 from "./UnpublishedEvent.vue?vue&type=style&index=1&id=765a9938&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765a9938",
  null
  
)

export default component.exports